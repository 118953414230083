import { createModel } from '@rematch/core';
import TagManager from 'react-gtm-module';

import {
  checkDiscount,
  deleteByIdDefaultPaymentMethod,
  getAccountSubscriptionStatus,
  getCheckoutSession,
  getSetupPayPallToken,
  getSubscriptionPlanList,
  patchByIdDefaultPaymentMethod,
  postCreatePaymentMethod,
  postCreatePaymentMethodStripe,
  postSubscruptuionAgreementCreate,
  postVerifyFsSubscription,
  putSubscriptionAgreementCancel,
  renewSubscription,
  upgradeProduct,
} from '@/api/services/billing';
import {
  getOnboardingStatus,
  getUserStatus,
  interestedEmployeePayrollPlan,
  postOnboardingBusinessAddress,
  postOnboardingBusinessProfile,
  postOnboardingBusinessPurpose,
  postOnboardingBusinessRole,
  postOnboardingUserProfile,
  updateAccountResource,
} from '@/api/services/user';
import {
  Account,
  BusinessLocationOnboardinInfo,
  BusinessOnboardingInfo,
  BusinessRoleModel,
  CallbackOnboardingInfo,
  OnboardingModel,
  ProfileInfo,
  StripeCheckoutResponse,
  SubscriptionInfo,
  UserModel,
} from '@/redux/dto/auth';
import { DiscountInformation } from '@/redux/dto/user';
import { TRIAL_DAYS } from '@/utils/constants';
import { callToast, checkAccountForPersona, formatErrorMessage } from '@/utils/helpers';

import type { RootModel } from '.';

type authState = {
  error: any;
  user: UserModel;
  paymentMethod: any;
  onboardingInfo: OnboardingModel;
  profileOnboardingInfo: ProfileInfo;
  businessRoleOnboardingInfo: BusinessRoleModel;
  discountCode: string | null;
  isPickedSubscriptionType: string | null;
  businessOnboardingInfo: BusinessOnboardingInfo;
  businessLocationOnboardingInfo: BusinessLocationOnboardinInfo;
  subscriptionInfo: SubscriptionInfo;
  showTrial: boolean;
  subscriptionPlan: { availableProducts: [] };
  callbackOnboardingInfo: CallbackOnboardingInfo;
};

export const auth = createModel<RootModel>()({
  state: {
    error: null,
    user: null,
    subscriptionInfo: null,
    subscriptionPlan: null,
    paymentMethod: null,
    discountCode: null,
    onboardingInfo: null,
    isPickedSubscriptionType: null,
    profileOnboardingInfo: null,
    showTrial: false,
    businessOnboardingInfo: {
      name: '',
      industry: '',
      customIndustry: '',
      numberOfEmployees: null,
      numberOfContractors: null,
    },
    businessRoleOnboardingInfo: {
      businessRole: '',
      otherRole: '',
      interest: [],
      otherInterest: '',
    },
    callbackOnboardingInfo: {
      role: null,
      numberOfEmployees: null,
      numberOfContractors: null,
      otherRole: null,
    },
  } as unknown as authState,
  reducers: {
    GET_USER_STATUS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        user: payload,
      };
    },
    GET_USER_STATUS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_ACCOUNT_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        onboardingInfo: { ...state.onboardingInfo, account: payload },
        user: { ...state.user, account: payload },
      };
    },
    GET_ONBOARDING_STATUS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        profileOnboardingInfo: {
          firstName: payload.account?.firstName,
          lastName: payload.account?.lastName,
          phoneNumber: payload.account?.phoneNumber ? payload.account?.phoneNumber : null,
        },
        businessOnboardingInfo: payload.account?.company?.length
          ? {
              businessType: payload.account[0]?.businessType,
              name: payload.account[0]?.name,
              numberOfEmployees: payload.account[0]?.numberOfEmployees
                ? payload.account[0]?.numberOfEmployees
                : 0,
              numberOfContractors: payload.account[0]?.numberOfContractors
                ? payload.account[0]?.numberOfContractors
                : 0,
              chooseEinOrSsn: payload.account[0]?.chooseEinOrSsn,
              ein: payload.account[0]?.ein,
              ssn: payload.account[0]?.ssn,
            }
          : state.businessOnboardingInfo,
        onboardingInfo: { ...state.onboardingInfo, account: payload.account },
        user: { ...state.user, account: payload.account },
      };
    },

    GET_ONBOARDING_STATUS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_SUBSCRIPTION_STATUS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        subscriptionInfo: payload,
        onboardingInfo: {
          ...state.onboardingInfo,
          hasActiveSubscription: !!payload?.activeSubscriptionAgreement,
        },
      };
    },
    GET_SUBSCRIPTION_PLAN_LIST_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        subscriptionPlan: payload,
      };
    },
    GET_SUBSCRIPTION_STATUS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    CREATE_PAYMENT_METHOD_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        paymentMethod: payload,
      };
    },
    CREATE_PAYMENT_METHOD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    RENEW_SUBSCRIPTION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
      };
    },
    RENEW_SUBSCRIPTION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SET_DEFAULT_PAYMENT_METHOD_SUCCESS: (state, payload) => {
      const { id } = payload;
      const updatedPaymentMethods = state.subscriptionInfo.paymentMethods.map(paymentMethod => {
        if (paymentMethod.id === id) {
          return {
            ...paymentMethod,
            isDefault: true,
          };
        }
        return {
          ...paymentMethod,
          isDefault: false,
        };
      });

      const updatedSubscriptionInfo = {
        ...state.subscriptionInfo,
        paymentMethods: updatedPaymentMethods,
      };

      return {
        ...state,
        error: null,
        subscriptionInfo: updatedSubscriptionInfo,
      };
    },
    SET_DEFAULT_PAYMENT_METHOD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_PAYMENT_METHOD_SUCCESS: (state, payload) => {
      const { id } = payload;

      const updatedPaymentMethods = state.subscriptionInfo.paymentMethods.filter(
        paymentMethod => paymentMethod.id !== id,
      );

      const updatedSubscriptionInfo = {
        ...state.subscriptionInfo,
        paymentMethods: updatedPaymentMethods,
      };

      return {
        ...state,
        error: null,
        subscriptionInfo: updatedSubscriptionInfo,
      };
    },
    DELETE_PAYMENT_METHOD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    CREATE_SUBSCRIPTION_AGREEMENT_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        subscriptionAgreement: payload,
      };
    },
    CREATE_SUBSCRIPTION_AGREEMENT_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    CANCEL_SUBSCRIPTION_AGREEMENT_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        subscriptionAgreement: payload,
      };
    },
    CANCEL_SUBSCRIPTION_AGREEMENT_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CHECKOUT_SESSION_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    GET_CHECKOUT_SESSION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPGRADE_CHECKOUT_PRODUCT_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    UPGRADE_CHECKOUT_PRODUCT_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SEND_ABOUT_YOUR_SELF_INFO_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        profileOnboardingInfo: payload,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            ...payload,
          },
        },
      };
    },
    SEND_ABOUT_YOUR_SELF_INFO_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SEND_BUSINESS_INFO_SUCCESS: (state, payload) => {
      // eslint-disable-next-line
      const { id, ...restPayload } = payload;
      return {
        ...state,
        error: null,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            businessProfile: {
              ...state.onboardingInfo?.account?.businessProfile,
              ...restPayload,
            },
          },
        },
      };
    },
    SEND_BUSINESS_ROLE_SUCCESS: (state, payload) => {
      return {
        ...state,
        //businessRoleOnboardingInfo: payload,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            businessProfile: {
              ...state.onboardingInfo?.account?.businessProfile,
              businessRole: payload.businessRole,
              otherRole: payload.otherRole,
              interest: payload.interest,
              otherInterest: payload.otherInterest,
              numberOfEmployees: payload.numberOfEmployees,
              numberOfContractors: payload.numberOfContractors,
            },
          },
        },
      };
    },
    SEND_BUSINESS_PURPOSE_SUCCESS: (state, payload) => {
      // eslint-disable-next-line
      const { id, ...restPayload } = payload;
      return {
        ...state,
        error: null,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            businessProfile: {
              ...state.onboardingInfo?.account?.businessProfile,
              ...restPayload,
            },
          },
        },
      };
    },
    SEND_BUSINESS_PURPOSE_FAILURE: (state, payload) => {
      return {
        ...state,
        //businessRoleOnboardingInfo: payload,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            businessProfile: {
              ...state.onboardingInfo?.account?.businessProfile,
              businessRole: payload.businessRole,
              otherRole: payload.otherRole,
              interest: payload.interest,
              otherInterest: payload.otherInterest,
              numberOfEmployees: payload.numberOfEmployees,
              numberOfContractors: payload.numberOfContractors,
            },
          },
        },
      };
    },
    SEND_BUSINESS_ROLE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SEND_BUSINESS_INFO_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SAVE_PICKED_PLAN_SUCCESS: (state, payload) => {
      return {
        ...state,
        isPickedSubscriptionType: payload.type,
      };
    },
    SAVE_PICKED_PLAN_FAILURE: (state, payload) => {
      return {
        ...state,
        isPickedSubscriptionType: null,
      };
    },
    SAVE_DISCOUNT_CODE_SUCCESS: (state, payload) => {
      return {
        ...state,
        discountCode: payload.code,
      };
    },
    SAVE_DISCOUNT_CODE_FAILURE: (state, payload) => {
      return {
        ...state,
        discountCode: null,
      };
    },
    SEND_BUSINESS_LOCATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            businessProfile: {
              ...state.onboardingInfo?.account?.businessProfile,
              address: payload,
            },
          },
        },
      };
    },
    SEND_BUSINESS_LOCATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SEND_BUSINESS_ADDRESS_INFO_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        onboardingInfo: {
          ...state.onboardingInfo,
          account: {
            ...(state.onboardingInfo?.account || {}),
            company: {
              ...state.onboardingInfo?.account.company,
              address: [{ payload }],
            },
          },
        },
      };
    },
    SEND_BUSINESS_ADDRESS_INFO_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    CHANGE_TRIAL_STATUS: (state, payload) => {
      return {
        ...state,
        error: null,
        showTrial: payload,
      };
    },
    SAVE_CALLBACK_ONBOARDING_INFORMATION: (state, payload) => {
      return {
        ...state,
        callbackOnboardingInfo: {
          role: payload?.role ?? null,
          numberOfEmployees: payload?.numberOfEmployees ?? null,
          numberOfContractors: payload?.numberOfContractors ?? null,
          otherRole: payload?.otherRole ?? null,
          trialDays: payload?.trialDays ?? TRIAL_DAYS,
        },
      };
    },
  },
  effects: dispatch => {
    const { auth } = dispatch;

    return {
      async getUserInformation(): Promise<UserModel | void> {
        try {
          const { data } = await getUserStatus();
          //GTM Part/
          TagManager.dataLayer({
            dataLayer: { email: data?.account?.email },
          });

          //Init profile fcWidget
          if (window.fcWidget) {
            window.fcWidget.setExternalId(data?.account?.email);
            window.fcWidget.user.setProperties({
              email: data?.account?.email,
              firstName: data?.account?.firstName,
            });
          }

          if (window.CobrowseIO) {
            window.CobrowseIO.customData = {
              user_id: data?.account?.id,
              user_name: `${data?.account?.firstName} ${data?.account?.lastName}`,
              user_email: data?.account?.email,
            };
          }
          //Init profile FreshWorksWidget
          if (window.FreshworksWidget) {
            window.FreshworksWidget('identify', 'ticketForm', {
              name: `${data?.account?.firstName} ${data?.account?.lastName}`,
              email: data?.account?.email,
            });
          }
          auth.GET_USER_STATUS_SUCCESS(data);
          return data;
        } catch (error: any) {
          callToast('error', error?.response?.data?.detail);
          auth.GET_USER_STATUS_FAILURE(error);
        }
      },
      async getOnboardinUserStatus(): Promise<OnboardingModel> {
        try {
          const { data } = await getOnboardingStatus();

          const formattedData = {
            account: data,
            hasActiveSubscription: false,
            subscriptionAgreements: [],
          };

          if (data) {
            const { numberOfContractors, numberOfEmployees, businessRole } =
              data.company?.[0] ?? {};

            const isAccountValidToSkipPersonaQuestions = !checkAccountForPersona(data.createdAt);

            const shouldCallSubscriptionApi =
              isAccountValidToSkipPersonaQuestions ||
              businessRole ||
              numberOfContractors === Number(numberOfContractors) ||
              numberOfEmployees === Number(numberOfEmployees);

            if (shouldCallSubscriptionApi) {
              const subscriptionResponse = await dispatch.auth.getSubscriptionStatus();

              formattedData.hasActiveSubscription =
                !!subscriptionResponse?.activeSubscriptionAgreement;

              formattedData.subscriptionAgreements = Array.isArray(
                subscriptionResponse?.subscriptionAgreements,
              )
                ? subscriptionResponse.subscriptionAgreements
                : [];
            }
          }

          auth.GET_ONBOARDING_STATUS_SUCCESS(formattedData);
          return formattedData;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response?.data?.detail);
          }
          auth.GET_ONBOARDING_STATUS_FAILURE(error);
          throw error;
        }
      },
      async getSubscriptionStatus(): Promise<SubscriptionInfo | undefined> {
        try {
          const { data } = await getAccountSubscriptionStatus();
          auth.GET_SUBSCRIPTION_STATUS_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.GET_SUBSCRIPTION_STATUS_FAILURE(error);
        }
      },
      async getSubscriptionPlan(): Promise<undefined> {
        try {
          const { data } = await getSubscriptionPlanList();
          auth.GET_SUBSCRIPTION_PLAN_LIST_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.GET_SUBSCRIPTION_PLAN_LIST_FALIUR(error);
        }
      },
      //Deprecated, don't use
      async getCheckoutStripeSession(payload): Promise<StripeCheckoutResponse | undefined> {
        try {
          const { data } = await getCheckoutSession(payload.product, payload.coupon);
          auth.GET_CHECKOUT_SESSION_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.GET_CHECKOUT_SESSION_FAILURE(error);
        }
      },

      async setupPayPallToken(): Promise<void> {
        try {
          const { data } = await getSetupPayPallToken();
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },
      async createPaymentMethod(payload): Promise<void> {
        try {
          const { data } = await postCreatePaymentMethod(payload.approvalTokenId);
          auth.CREATE_PAYMENT_METHOD_SUCCESS(data);
          return data;
        } catch (error: any) {
          auth.CREATE_PAYMENT_METHOD_FAILURE(error);
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },
      async verifyFsSubscription(fsSubscriptionId): Promise<void> {
        try {
          const { data } = await postVerifyFsSubscription(fsSubscriptionId);
          auth.GET_SUBSCRIPTION_STATUS_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response?.data?.detail);
          }
        }
      },
      async createPaymentMethodStripe(payload): Promise<void> {
        try {
          const { paymentMethodId, email } = payload;
          const { data } = await postCreatePaymentMethodStripe(paymentMethodId, email);
          auth.CREATE_PAYMENT_METHOD_SUCCESS(data);
          return data;
        } catch (error: any) {
          auth.CREATE_PAYMENT_METHOD_FAILURE(error);
          const errorMessage = formatErrorMessage(error);
          if (errorMessage) {
            callToast('error', errorMessage);
          }
        }
      },

      async createSubscriptionAgreement(payload): Promise<void> {
        try {
          const { data } = await postSubscruptuionAgreementCreate(payload.product, payload.coupon);
          auth.CREATE_SUBSCRIPTION_AGREEMENT_SUCCESS(data);
          return data;
        } catch (error: any) {
          auth.CREATE_SUBSCRIPTION_AGREEMENT_FAILURE(error);
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },

      async renewSubscription(payload): Promise<any> {
        try {
          const { data } = await renewSubscription(payload.id);
          await dispatch.auth.getSubscriptionStatus();
          auth.RENEW_SUBSCRIPTION_SUCCESS(data);
          return data;
        } catch (error: any) {
          auth.RENEW_SUBSCRIPTION_FAILURE(error);
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },
      async checkDiscountCode(payload): Promise<DiscountInformation | undefined> {
        try {
          const { data } = await checkDiscount(payload.code, payload.product);
          return data;
        } catch (error: any) {
          callToast('warning', error.response.data.detail);
        }
      },

      async setDefaultPaymentMethod(payload): Promise<void> {
        try {
          const { data } = await patchByIdDefaultPaymentMethod(payload.id);
          auth.SET_DEFAULT_PAYMENT_METHOD_SUCCESS({ id: payload.id });
          return data;
        } catch (error: any) {
          auth.SET_DEFAULT_PAYMENT_METHOD_SUCCESS(error);
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },
      async deletePaymentMethod(payload): Promise<void> {
        try {
          const { data } = await deleteByIdDefaultPaymentMethod(payload.id);
          auth.DELETE_PAYMENT_METHOD_SUCCESS({ id: payload.id });
          return data;
        } catch (error: any) {
          auth.DELETE_PAYMENT_METHOD_FAILURE(error);
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },

      async cancelSubscriptionAgreement(payload): Promise<void> {
        try {
          const { cancelReason, cancelFeedback } = payload;
          const { data } = await putSubscriptionAgreementCancel(
            payload.id,
            cancelReason,
            cancelFeedback,
          );
          dispatch.auth.getSubscriptionStatus();
          return data;
        } catch (error: any) {
          auth.CANCEL_SUBSCRIPTION_AGREEMENT_FAILURE(error);
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },

      async upgradeCheckoutProduct(payload): Promise<void> {
        try {
          const { product, subscriptionAgreementId } = payload;
          const { data } = await upgradeProduct(product, subscriptionAgreementId);
          auth.UPGRADE_CHECKOUT_PRODUCT_SUCCESS(data);
          return data;
        } catch (error: any) {
          callToast(
            'error',
            'There has been an issue trying to upgrade your plan, please try again later or contact support',
          );
          auth.UPGRADE_CHECKOUT_PRODUCT_FAILURE(error);
        }
      },
      async saveDiscountCode(payload) {
        try {
          auth.SAVE_DISCOUNT_CODE_SUCCESS(payload);
        } catch (error: any) {
          callToast('error', error);
          auth.SAVE_DISCOUNT_CODE_FAILURE(error);
        }
      },
      async savePickedPlan(payload) {
        try {
          auth.SAVE_PICKED_PLAN_SUCCESS(payload);
        } catch (error: any) {
          callToast('error', error);
          auth.SAVE_PICKED_PLAN_FAILURE(error);
        }
      },
      async postOnboardingUserInformation(payload): Promise<void> {
        try {
          const { data } = await postOnboardingUserProfile(payload);
          auth.SEND_ABOUT_YOUR_SELF_INFO_SUCCESS(data);
          return data;
        } catch (error: any) {
          console.log(error, 'error');
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.SEND_ABOUT_YOUR_SELF_INFO_FAILURE(error);
        }
      },
      async postOnboardingBusinessInformation(payload): Promise<unknown> {
        try {
          const { data } = await postOnboardingBusinessProfile(payload);
          auth.SEND_BUSINESS_INFO_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.SEND_BUSINESS_INFO_FAILURE(error);
        }
      },
      async postOnboardingBusinessPurposeInformation(payload): Promise<unknown> {
        try {
          const { data } = await postOnboardingBusinessPurpose(payload);
          auth.SEND_BUSINESS_PURPOSE_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.SEND_BUSINESS_PURPOSE_FAILURE(error);
        }
      },
      async postOnboardingBusinessAddress(payload): Promise<void> {
        try {
          const { data } = await postOnboardingBusinessAddress(payload);
          auth.SEND_BUSINESS_LOCATION_SUCCESS(data);
          dispatch.auth.getUserInformation();
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.SEND_BUSINESS_LOCATION_FAILURE(error);
        }
      },
      async postOnboardingBusinessRole(payload): Promise<void> {
        try {
          const { data } = await postOnboardingBusinessRole(payload);
          auth.SEND_BUSINESS_ROLE_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          auth.SEND_BUSINESS_ROLE_FAILURE(error);
        }
      },
      async postNotifyMeAboutFeaturePayrollEmplyee(payload, state): Promise<void> {
        try {
          const id = state.auth.user.account.id;
          const { data } = await interestedEmployeePayrollPlan(id);
          dispatch.auth.getUserInformation();
          return data;
        } catch (error: any) {
          callToast('error', error?.response?.data?.detail);
        }
      },
      async updateTrialStatus(payload, state): Promise<null | undefined> {
        try {
          auth.CHANGE_TRIAL_STATUS(payload);
          return null;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },
      saveCallbackOnboardingInformation(payload) {
        try {
          auth.SAVE_CALLBACK_ONBOARDING_INFORMATION(payload);
          return null;
        } catch (error) {
          console.log(error);
        }
      },
      async getAccountInformation(): Promise<Account | undefined> {
        try {
          const { data } = await getOnboardingStatus();
          auth.UPDATE_ACCOUNT_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
        }
      },
      async updateAccountInformation(payload): Promise<Account | undefined> {
        try {
          const { data } = await updateAccountResource(payload.id, payload);
          auth.UPDATE_ACCOUNT_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          if (error.response) {
            callToast('error', error.response.data.detail);
          }
          throw error;
        }
      },
    };
  },
});
