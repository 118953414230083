import { Dialog } from '@headlessui/react';
import { Bars3BottomLeftIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '@/assets/logoDark.svg';
import { ReactComponent as LogoShort } from '@/assets/payStubLogoShort.svg';
import { DialogComponent, LogoutModal, SubscriptionGlobalBanner, UserAvatar } from '@/components';
import ReactAriaButton from '@/components/Button/Button';
import Menu from '@/components/ui/Menu/Menu';
import SupportHelp from '@/components/ui/SupportHelp/SupportHelp';
import { CalendlyModal } from '@/modules/myAccount/components/CancelSubscriptionModal/modals/CalendlyModal';
import * as routes from '@/routes/routes';
import { navigation } from '@/utils/constants';
import { keyUp } from '@/utils/helpers';

import MyAccountModal from './component/MyAccountModal';
import useHeader from './useHeader';

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

type HeaderProps = {
  showHelp: boolean;
  showProfile: boolean;
  showMobileMenuIcon: boolean;
};

const Header = ({ showHelp, showProfile, showMobileMenuIcon }: HeaderProps) => {
  const {
    bannerInfo,
    handleLogout,
    isOpenedLogoutModal,
    setIsOpenedLogoutModal,
    mobileMenuOpen,
    setMobileMenuOpen,
    user,
    currentPath,
    businessRole,
    email,
    numberOfContractors,
    numberOfEmployees,
    otherRole,
    handleCalendlyModal,
    handleHeaderMenuActions,
    menuItemList,
    isOpenMyAccountModal,
    setIsOpenMyAccountModal,
    isCalendlyModalOpen,
    firstName,
    lastName,
  } = useHeader();

  const {
    bannerVariantToShow,
    gracePeriodEndDate,
    nextProcessingDate,
    remainingDays,
    isFreeTrailOver,
  } = bannerInfo || {};

  return (
    <header className="w-full">
      {isAppModeDemo ? (
        <div className="flex h-[72px] w-full items-center justify-between bg-[#EBF3F0] px-[10px] lg:px-[40px]">
          <span className="hidden font-F37Bolton-Medium text-[20px] lg:block">
            This is just a demo, create an account and run payroll for free for 30 days.
          </span>
          <span className="block max-w-[160px] font-F37Bolton-Medium text-[18px] lg:hidden">
            This is just a demo, try it out for real.
          </span>
          <a
            href={`https://app.paystubs.com/signup?we=${email}&role=${businessRole}&employees=${numberOfEmployees}&contractors=${numberOfContractors}&otherRole=${otherRole}`}
            className="flex h-[48px] w-[185px] items-center justify-center rounded-[8px] bg-emerald-500 text-center text-white hover:bg-emerald-600 lg:w-[250px]"
          >
            Start 30-Day Free Trial
          </a>
        </div>
      ) : (
        bannerVariantToShow && (
          <div>
            <SubscriptionGlobalBanner
              bannerVariant={bannerVariantToShow}
              remainingDays={remainingDays}
              cancellationDate={nextProcessingDate}
              gracePeriodEndDate={gracePeriodEndDate}
              isFreeTrailOver={isFreeTrailOver}
            />
          </div>
        )
      )}

      <div className="flex h-[72px] w-full items-center justify-between border-b border-gray-50 bg-white px-5">
        <nav className="flex items-center justify-between py-[14px]" aria-label="Global">
          <div className="flex items-center gap-4">
            {showMobileMenuIcon && (
              <div className="flex lg:hidden">
                <ReactAriaButton
                  variant="text"
                  className="inline-flex items-center justify-center focus:outline-none"
                  onPress={() => {
                    setMobileMenuOpen(!mobileMenuOpen);
                  }}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3BottomLeftIcon className="w-6 text-black" />
                </ReactAriaButton>
              </div>
            )}

            <Link to={routes.DASHBOARD}>
              <Logo className="hidden xs:flex" />
              <LogoShort className="xs:hidden" />
            </Link>
          </div>
        </nav>
        <div className="flex items-center justify-center gap-2 sm:gap-[15px]">
          {showHelp && <SupportHelp onBookCall={handleCalendlyModal} />}
          {showProfile && (
            <div className=" flex items-center justify-center text-base font-medium leading-6 ">
              <Menu
                onAction={handleHeaderMenuActions}
                menuWrapperClasses="min-w-[250px]"
                menuButtonClasses="border group p-1 !border-solid	!rounded-full h-auto"
                menuButtonLabel={
                  <div className="flex items-center gap-2">
                    <UserAvatar height={28} width={28} src={user?.picture} />
                    <div className="hidden capitalize text-black lg:flex">
                      {firstName && lastName ? `${firstName} ${lastName[0]}.` : 'User'}
                    </div>
                  </div>
                }
                menuButtonVariant="text"
                menuButtonIcon={
                  <ChevronDownIcon className="mx-1 w-5 text-gray-300 transition-[margin] group-hover:mt-0.5" />
                }
                menuItemsList={menuItemList}
              />
            </div>
          )}

          {isOpenMyAccountModal && (
            <MyAccountModal
              isOpen={isOpenMyAccountModal}
              onCancel={() => setIsOpenMyAccountModal(false)}
            />
          )}
          {isCalendlyModalOpen && (
            <DialogComponent
              open={isCalendlyModalOpen}
              onCancel={handleCalendlyModal}
              children={
                <div className="relative h-screen min-h-screen">
                  <CalendlyModal onClose={handleCalendlyModal} />
                </div>
              }
              width="w-[100vw] lg:w-[610px]"
              height="h-[100vh] lg:max-h-[800px]"
            />
          )}
        </div>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="sm:ring-gray-900/10 fixed inset-y-0 right-0 z-50 flex h-screen w-full flex-col overflow-hidden bg-white sm:ring-1">
            <nav
              className="fixed top-0 z-10 flex h-[56px] w-full items-center justify-between border-b border-gray-50 bg-white px-5"
              aria-label="Global"
            >
              <div className="flex items-center gap-4">
                <div className="flex lg:hidden">
                  <ReactAriaButton
                    variant="text"
                    className="inline-flex items-center justify-center focus:outline-none"
                    onPress={() => {
                      setMobileMenuOpen(!mobileMenuOpen);
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="w-6 text-black" />
                  </ReactAriaButton>
                </div>

                <Link to={routes.DASHBOARD}>
                  <Logo className="hidden xs:flex" />
                  <LogoShort className="xs:hidden" />
                </Link>
              </div>
            </nav>

            <div className="mb-11 mt-14 flex-1 overflow-auto pt-5">
              {navigation.map((item, index) => {
                const isCurrent = item.path === currentPath;
                if (item.label) {
                  return (
                    <span
                      key={index}
                      className="ml-6 mr-[12px] flex pb-[15px] pt-[20px] text-sm text-gray-400"
                      aria-hidden="true"
                    >
                      {item.name}
                    </span>
                  );
                }
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    onClick={() => setMobileMenuOpen(false)}
                    className={`
                          ${
                            isCurrent
                              ? 'border-l-[3px] border-emerald-500 text-emerald-500'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                          }
                          group flex h-[44px] items-center
                        `}
                  >
                    <item.icon
                      className={`
                            ${
                              isCurrent
                                ? 'text-emerald-500'
                                : 'text-gray-400 group-hover:text-gray-500'
                            }
                            ml-6 mr-[12px] size-6
                          `}
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                  </Link>
                );
              })}
            </div>

            <div className="absolute bottom-0 flex w-full flex-col items-start justify-center bg-white">
              <span className="flex h-11 w-full items-center justify-center border-t text-sm text-gray-400 menu-height:static">
                <span
                  onKeyUp={keyUp}
                  tabIndex={0}
                  role="button"
                  className="mr-[5px] text-gray-400 underline underline-offset-2 hover:cursor-pointer hover:text-gray-500"
                  onClick={() =>
                    window.open('https://www.paystubs.com/legal/terms-and-conditions', '_blank')
                  }
                >
                  T&C
                </span>{' '}
                and{' '}
                <span
                  onKeyUp={keyUp}
                  tabIndex={0}
                  role="button"
                  className="ml-[5px] text-gray-400 underline underline-offset-2 hover:cursor-pointer hover:text-gray-500"
                  onClick={() =>
                    window.open('https://www.paystubs.com/legal/privacy-policy', '_blank')
                  }
                >
                  Privacy Policy
                </span>
              </span>
            </div>
          </Dialog.Panel>
        </Dialog>
        {isOpenedLogoutModal && (
          <LogoutModal
            isOpen={isOpenedLogoutModal}
            toggleModal={isOpen => setIsOpenedLogoutModal(isOpen)}
            onAction={handleLogout}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
