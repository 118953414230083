import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRightEndOnRectangleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/redux/store';
import { checkAccountForSubscriptionReminder } from '@/utils/helpers';

import { ACCOUNT_BILLING, LOGOUT } from './constants';

const menuItemList = [
  {
    label: 'Accounts and billing',
    key: ACCOUNT_BILLING,
    startIcon: <Cog6ToothIcon className="size-[20px] text-gray-300" />,
    className: '',
  },
  {
    label: 'Logout',
    key: LOGOUT,
    startIcon: <ArrowRightEndOnRectangleIcon className="size-[20px] text-red-500" />,
    className: 'text-red-500',
  },
];

const useHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isOpenedLogoutModal, setIsOpenedLogoutModal] = useState<boolean>(false);
  const [isOpenMyAccountModal, setIsOpenMyAccountModal] = useState(false);
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  const { logout, user } = useAuth0();

  const subscriptionInfo = useSelector((state: RootState) => state.auth?.subscriptionInfo);

  const account = useSelector((state: RootState) => state?.auth?.user?.account);

  const { firstName, lastName } = account || {};

  const demoInfo = useSelector((state: RootState) => state.demo?.demoInfo);

  const {
    email,
    businessRole,
    numberOfEmployees,
    numberOfContractors,
    otherBusinessRole: otherRole,
  } = demoInfo || {};

  const currentPath = window.location.pathname;

  const handleLogout = async () => {
    try {
      localStorage.clear();
      logout();
      window.fcWidget.user.clear().then(
        function () {
          console.log('FcWidget User cleared');
        },
        function () {
          console.error('FcWidget User Not cleared');
        },
      );
      window.FreshworksWidget('destroy');
    } catch (error) {
      alert(error);
    }
  };

  const bannerInfo = checkAccountForSubscriptionReminder(subscriptionInfo);

  const handleCalendlyModal = () => {
    setIsCalendlyModalOpen(!isCalendlyModalOpen);
  };

  const handleHeaderMenuActions = (action: string | number) => {
    if (action === LOGOUT) {
      setIsOpenedLogoutModal(true);
    } else if (action === ACCOUNT_BILLING) setIsOpenMyAccountModal(true);
  };

  return {
    bannerInfo,
    handleLogout,
    isOpenedLogoutModal,
    setIsOpenedLogoutModal,
    mobileMenuOpen,
    setMobileMenuOpen,
    user,
    currentPath,
    businessRole,
    email,
    numberOfContractors,
    numberOfEmployees,
    otherRole,
    handleCalendlyModal,
    handleHeaderMenuActions,
    menuItemList,
    isOpenMyAccountModal,
    setIsOpenMyAccountModal,
    isCalendlyModalOpen,
    firstName,
    lastName,
  };
};

export default useHeader;
