import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';

import {
  checkIsEmployeeInvitation,
  getCookieData,
  getEmployeeInvitationToken,
  getToken,
} from '@/utils/helpers';

axios.defaults.baseURL = import.meta.env.APP_BACKEND_END_POINT;

axios.interceptors.request.use(
  async config => {
    const APP_URL_COOKIE = getCookieData('APP_URL');

    if (APP_URL_COOKIE) {
      axios.defaults.baseURL = APP_URL_COOKIE;
    }

    const excludedUrls = ['/discount/verify'];
    const isExcluded = excludedUrls.some(url => config?.url?.includes(url));
    const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';
    const token = localStorage.getItem('accessToken');

    if (!isExcluded && (!isAppModeDemo || (isAppModeDemo && token))) {
      const isEmployeeInvitation = !!config?.url && checkIsEmployeeInvitation();

      let accessToken = null;
      let tokenType = `Bearer`;

      if (isEmployeeInvitation) {
        accessToken = getEmployeeInvitationToken();
        tokenType = `Invitation`;
      } else {
        accessToken = await getToken();
        if (!accessToken) {
          localStorage.clear();
          window.location.href = window.location.origin + '/login';
        }
      }

      config.headers['Authorization'] = `${tokenType} ${accessToken}`;

      if (config.method === 'patch') {
        config.headers['Content-Type'] = 'application/merge-patch+json';
      }
    }

    const miscDataHeader = {
      HUBSPOT_UTK: getCookieData('hubspotutk'),
      GA_CLIENT_ID: getCookieData('_ga'),
      GA_SESSION_ID: getCookieData('_ga_MDB3MHPDXM'),
      GA_CLICK_ID: getCookieData('_gcl_aw'),
      FBC: getCookieData('_fbc'),
      FBP: getCookieData('_fbp'),
      TT_CLICK_ID: getCookieData('ttclid'),
      TTP: getCookieData('_ttp'),
    };

    // _.pickBy removes undefined values
    config.headers['X-TAG-DATA'] = new URLSearchParams(_.pickBy(miscDataHeader)).toString();

    try {
      // @ts-ignore
      const FsSessionURL = FullStory.getCurrentSessionURL(true);
      if (FsSessionURL) {
        config.headers['X-FS-SESSION-URL'] = FsSessionURL;
      }
    } catch (error) {
      console.error('FS-Session-Error', error);
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

export const getCall = (url: string, params?: AxiosRequestConfig['params']) =>
  axios.get(url, { params });

export const getCallBlob = (url: string, params?: AxiosRequestConfig['params']) =>
  axios.get(url, { params, responseType: 'blob' });

export const postCall = <T>(
  url: string,
  data: T,
  headers?: AxiosRequestConfig['headers'],
  params?: AxiosRequestConfig['params'],
) => {
  const config: AxiosRequestConfig = {
    headers: { ...headers },
    params,
  };
  return axios.post(url, data, config);
};

export const putCall = <T>(
  url: string,
  data: T,
  headers?: AxiosRequestConfig['headers'],
  params?: AxiosRequestConfig['params'],
) => {
  const config: AxiosRequestConfig = {
    headers: { ...headers },
    params,
  };
  return axios.put(url, data, config);
};

export const deleteCall = (url: string) => axios.delete(url);

export const patchCall = <T>(
  url: string,
  data: T,
  headers?: AxiosRequestConfig['headers'],
  params?: AxiosRequestConfig['params'],
) => {
  const config: AxiosRequestConfig = {
    headers: { ...headers },
    params,
  };
  return axios.patch(url, data, config);
};

export default axios;
