import { CalendarIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';

import Button from '@/components/Button/Button';
import { RootState } from '@/redux/store';
import { SUPPORT_CONTACT_INFO } from '@/utils/constants';

type SupportBoxProps = {
  onBookCall?: () => void;
};

const SupportBox = ({ onBookCall }: SupportBoxProps) => {
  const account = useSelector((state: RootState) => state.auth?.user?.account);

  const { firstName, lastName, email } = account || {};

  const openTicketThrowEmail = () => {
    window.FreshworksWidget('identify', 'ticketForm', {
      name: `${firstName} ${lastName}`,
      email: email,
    });
    window.FreshworksWidget('open');
  };

  const supportItems = [
    ...(onBookCall
      ? [
          {
            id: 'bookCall',
            label: 'Book a call',
            icon: <CalendarIcon className="w-5 text-gray-400 group-hover:text-emerald-500" />,
            onButtonPress: onBookCall,
          },
        ]
      : []),
    {
      id: 'email',
      label: 'Email',
      icon: <EnvelopeIcon className="w-5 text-gray-400 group-hover:text-emerald-500" />,
      onButtonPress: openTicketThrowEmail,
    },
    {
      id: 'callNow',
      label: `Call now: ${SUPPORT_CONTACT_INFO.mobileNumber}`,
      icon: <PhoneIcon className="w-5 text-gray-400 group-hover:text-emerald-500" />,
      onButtonPress: () => (window.location.href = `tel:+${SUPPORT_CONTACT_INFO.mobileNumber}`),
    },
  ];

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-wrap gap-[14px]">
          <div>
            <img
              src="https://uploads-ssl.webflow.com/63d39d61533099307dbc0bf8/6639dbfeb3883789ae817a53_JaysonHeadshot.png"
              className="size-12"
              alt="jayson"
            />
          </div>
          <div>
            <p className="font-F37Bolton-Medium text-lg">Hi, I&apos;m Jayson!</p>
            <p className="text-sm text-gray-400">I&apos;m here if you need help.</p>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-2">
          <Button
            variant="primary"
            parentClasses="py-3 w-full text-sm"
            height="h-auto"
            onPress={() => {
              window.fcWidget.open();
            }}
          >
            Chat with me
          </Button>
        </div>
        <div className="mt-3 flex flex-col">
          {supportItems.map(item => (
            <Button
              key={item.id}
              variant="text"
              parentClasses="flex items-center gap-2 !justify-start py-2 pr-2 group hover:text-emerald-500"
              onPress={() => {
                item.onButtonPress();
              }}
              height="h-auto"
            >
              {item.icon} {item.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupportBox;
