import { useAuth0 } from '@auth0/auth0-react';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Dispatch, RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { useLocationEffect } from '@/utils/helpers';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const growthbook = useGrowthBook();

  const account = useSelector((state: RootState) => state?.auth?.user?.account);

  const subscriptionAgreements = useSelector(
    (state: RootState) => state.auth?.subscriptionInfo?.subscriptionAgreements,
  );

  const hasSubscriptions = Array.isArray(subscriptionAgreements) && !!subscriptionAgreements.length;

  const authErrMessage = useSelector((state: RootState) => state.auth?.error?.error);

  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  useEffect(() => {
    // Just forcing a refresh of identity, otherwise it only happens on /callback
    if (!isAppModeDemo) {
      dispatch.auth.getUserInformation();
      dispatch.auth.getOnboardinUserStatus();
    }
  }, []);

  useEffect(() => {
    if (account?.id) {
      growthbook?.setAttributes({
        userId: account?.id,
        auth0id: account?.auth0Id,
        email: account?.email,
      });
    }
  }, [account]);

  useLocationEffect();

  const handleAuth0Logout = () => {
    const redirectUrl = window.location.pathname + window.location.search;

    logout({
      logoutParams: {
        returnTo: window.location.origin + `/login?redirectTo=${redirectUrl}`,
      },
    });
    window.fcWidget?.user?.clear();
    localStorage.clear();
  };

  const handleAuthCheck = async () => {
    if (import.meta.env.APP_MODE !== 'DEMO' && !isAuthenticated) {
      if (authErrMessage === 'login_required') {
        navigate(routes.LOGOUT);
      }
      try {
        const token = await getAccessTokenSilently();
        if (!token) {
          handleAuth0Logout();
        }
      } catch (error) {
        handleAuth0Logout();
      }
    }
  };

  useEffect(() => {
    handleAuthCheck();
  }, [hasSubscriptions, authErrMessage, isAuthenticated]);

  if (import.meta.env.APP_MODE === 'DEMO') {
    return <Outlet />;
  }

  return hasSubscriptions ? <Outlet /> : <></>;
};

export default PrivateRoute;
