import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { LogoutModal } from '@/components';
import { RootState } from '@/redux/store';
import { navigation } from '@/utils/constants';
import { checkAccountForSubscriptionReminder, keyUp } from '@/utils/helpers';

const SideBar = () => {
  const { logout } = useAuth0();
  const location = useLocation();
  const currentPath = location.pathname;

  const [isOpenedLogoutModal, setisOpenedLogoutModal] = useState<boolean>(false);
  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';
  const subscriptionInfo = useSelector((state: RootState) => state.auth?.subscriptionInfo);
  const bannerInfo = checkAccountForSubscriptionReminder(subscriptionInfo);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      logout();
      window.fcWidget.user.clear().then(
        function () {
          console.log('FcWidget User cleared');
        },
        function () {
          console.error('FcWidget User Not cleared');
        },
      );
      window.FreshworksWidget('destroy');
    } catch (error) {
      alert(error);
    }
  };

  // Additional height of sidebar from the demo banner or the subscription reminder banner
  const additionHeaderHeightOfBanner = isAppModeDemo
    ? 72
    : bannerInfo?.bannerVariantToShow
    ? 60
    : 0;

  return (
    <div
      className="fixed hidden max-h-[100vh] w-[270px] flex-col border-r border-gray-100 bg-white lg:flex"
      style={{
        height: `calc(100vh - 72px - ${additionHeaderHeightOfBanner}px)`,
      }}
    >
      <div className="relative flex h-full flex-col overflow-hidden">
        <nav
          className="h-full flex-1 flex-col gap-y-1 overflow-y-auto bg-white py-4"
          aria-label="Sidebar"
        >
          {navigation.map((item, index) => {
            const isCurrent = currentPath.startsWith(item.path);
            if (item.label) {
              return (
                <span
                  key={index}
                  className="ml-6 mr-[12px] flex pb-[15px] pt-[20px] text-sm text-gray-400 menu-height:py-0"
                  aria-hidden="true"
                >
                  {item.name}
                </span>
              );
            }
            return (
              <Link
                key={item.path}
                to={item.path}
                className={`
                  ${
                    isCurrent
                      ? 'border-l-[3px] border-emerald-500 text-emerald-500'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }
                  group flex h-[44px] items-center
                `}
              >
                <item.icon
                  className={`
                    ${isCurrent ? 'text-emerald-500' : 'text-gray-400 group-hover:text-gray-500'}
                    ml-6 mr-[12px] size-6
                  `}
                  aria-hidden="true"
                />
                <span className="flex-1">{item.name}</span>
              </Link>
            );
          })}
        </nav>
        <div className="absolute bottom-0 flex w-full flex-col items-start justify-center bg-white">
          <span className="flex h-11 w-full items-center justify-center border-t text-sm text-gray-400 menu-height:static">
            <span
              onKeyUp={keyUp}
              tabIndex={0}
              role="button"
              className="mr-[5px] text-gray-400 underline underline-offset-2 hover:cursor-pointer hover:text-gray-500"
              onClick={() =>
                window.open('https://www.paystubs.com/legal/terms-and-conditions', '_blank')
              }
            >
              T&C
            </span>{' '}
            and{' '}
            <span
              onKeyUp={keyUp}
              tabIndex={0}
              role="button"
              className="ml-[5px] text-gray-400 underline underline-offset-2 hover:cursor-pointer hover:text-gray-500"
              onClick={() => window.open('https://www.paystubs.com/legal/privacy-policy', '_blank')}
            >
              Privacy Policy
            </span>
          </span>
        </div>
      </div>

      {isOpenedLogoutModal && (
        <LogoutModal
          isOpen={isOpenedLogoutModal}
          toggleModal={isOpen => setisOpenedLogoutModal(isOpen)}
          onAction={handleLogout}
        />
      )}
    </div>
  );
};

export default SideBar;
