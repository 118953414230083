//AUTH
export const CALLBACK = '/callback';
export const AUTH = '/auth';
export const SIGNUP = '/signup';
export const SIGNUP_STEPPER = '/signup-stepper';

export const SIGNIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const CREATE_NEW_PASSWORD = '/create-new-password';
export const LOG_OUT = '/log-out';
export const SIGNUP_SIMPLE = '/signup-simple';

// Common
export const PRIVACY_POLICY = '/privacy-policy';
export const HELP = '/help';
export const FEEDBACK = '/feedback';
export const ERROR_PAGE = '/error-page';

//Dashboard
export const DASHBOARD = '/dashboard/main';

export const ADD_EMPLOYEE = '/dashboard/employees/add';
export const ADD_CONTRACTOR = '/dashboard/contractors/add';

export const TEAM_MEMBERS = '/dashboard/team-members';
export const CURRENT_EMPLOYEE = '/dashboard/team-members/employee/:employeeId';
export const CURRENT_CONTRACTOR = '/dashboard/team-members/contractor/:contractorId';

export const COMPANY = '/dashboard/company';
export const PAY_CONTRACTORS = '/dashboard/pay-contractors';
export const RUN_PAYROLL = '/dashboard/run-payroll';
export const RUN_PAYROLL_ADD_INFORMATION = '/dashboard/run-payroll/:payrollId/add-information';
export const RECORD_CONTRACTOR_PAYMENT_ADD_INFORMATION =
  '/dashboard/pay-contractors/:paymentId/add-information';
export const RECORD_CONTRACTOR_PAYMENT_REVIEW = '/dashboard/pay-contractors/:paymentId/review';
export const RECORD_CONTRACTOR_APPROVED_PAYMENT = '/dashboard/pay-contractors/:paymentId/approved';
export const RECORD_PAYMENT_ADD_INFORMATION = '/dashboard/pay-contractors/add-information';
export const RECORD_PAYMENT_DETAILS = '/dashboard/pay-contractors/:paymentId/details';

export const RUN_PAYROLL_DETAILS = '/dashboard/run-payroll/:payrollId/details';
export const RUN_PAYROLL_PREVIEW = '/dashboard/run-payroll/:payrollId/review';
export const RUN_PAYROLL_APPROVE = '/dashboard/run-payroll/:payrollId/approved';
export const RUN_PAYROLL_DETAILS_PAYSTUB = '/dashboard/run-payroll/details/paystub';
export const PAYROLL_SCHEDULE = '/dashboard/payroll-schedule';
export const PAYROLL_SCHEDULE_CREATE = '/dashboard/payroll-schedule/create';
export const PAYROLL_SCHEDULE_EDIT = '/dashboard/payroll-schedule/edit';

export const PAYROLL_HISTORY = '/dashboard/payroll-history';
export const MY_ACCOUNT = '/dashboard/my-account';
export const MY_ACCOUNT_BILLING_CENTER_MANAGE = '/dashboard/my-account/billing-center/manage';
export const MY_ACCOUNT_BILLING_CENTER = '/dashboard/my-account/billing';
export const PAYROLL_HISTORY_CURRENT = '/dashboard/payroll-history/:id';
export const PAYROLL_HISTORY_CURRENT_EMPLOYEE = '/dashboard/payroll-history/employee/:id';
export const PAYROLL_HISTORY_CURRENT_CONTRACTOR =
  '/dashboard/payroll-history/contractor/:contractorId/:paymentId';

export const PAYROLL_SETTINGS = '/dashboard/payroll-settings';

//payroll history
export const PAYROLL_HISTORY_SETUP = '/dashboard/run-payroll/history-setup';
export const EMPLOYEE_PAYROLL_HISTORY_SETUP = '/dashboard/run-payroll/history-setup/:employeeId';

export const DASHBOARD_UPDATE_EING_SSN = '/dashboard/company/:companyId/update-ein-ssn';
export const SUPPORT = '/dashboard/support';
export const TAX_FORM_CONTRACTOR_1099S = '/dashboard/tax-forms/contractor-1099s';
export const TAX_FORM_EMPLOYEE_W2 = '/dashboard/tax-forms/employee-w2';

// Business
export const TAX_FORMS = '/dashboard/tax-forms';
export const TAX_LIABILITY = '/dashboard/tax-liability';
export const TAX_LIABILITY_REPORT = '/dashboard/tax-liability/report/:scheduleType';

//Other
export const MY_ACCOUUNT = '/my-account';
export const LOGOUT = '/logout';
export const RECEIPT_DOWNLOAD = '/receipt-download/:companyId/:id';
export const RECEIPT_PRINT_CONTRACTORS = '/receipt-print/:companyId/:id';
export const RECEIPT_PRINT_CONTRACTOR = '/receipt-print/:companyId/:contractorId/:id';

export const SUCCESS_PURCHASE = '/success';
export const SUCCESS_PAYPALL = '/paypal/success';
export const SUCCESS_PURCHASE_CONTINUE_ONBOARDING = '/success/callback';

export const CANCELL_PURCHASE = '/cancel';

// Routes which used previously and will be removed
export const ADD_TEAM_MEMBER = '/dashboard/members/add';
export const NEW_CONTRACTOR = '/dashboard/members/add/contractor';

//For Debug
export const TEST_MOBILE_PAY = '/test/mobilepay';

//Employee Tax Form Invitation Link
export const EMPLOYEE_INVITATION = '/employee/invitation/:invitationToken';

//Account Setup
export const ACCOUNT_SETUP = '/account-setup';
export const ACCOUNT_SETUP_COMPANY = '/account-setup/company';
export const ACCOUNT_SETUP_PAYROLL_SETTINGS = '/account-setup/payroll-settings';
export const ACCOUNT_SETUP_TAXES = '/account-setup/taxes';
export const ACCOUNT_SETUP_EMPLOYEE = '/account-setup/employees';

export const ACCOUNT_SETUP_CURRENT_EMPLOYEE = '/account-setup/employees/:employeeId';
export const ACCOUNT_SETUP_CURRENT_CONTRACTOR = '/account-setup/contractors/:contractorId';

export const ACCOUNT_SETUP_FINISH_ONBOARDING = '/account-setup/finish-onboarding';
